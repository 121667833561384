import * as AdaActionsTypes from "./actionTypes";

export const getAdaFiltersData = (payload, filterType) => ({
  type: AdaActionsTypes.GET_FILTERS_ACTION,
  payload,
  filterType,
});

export const getAdaMultiData = (payload) => ({
  type: payload?.past_flag ? AdaActionsTypes.GET_ADA_MULTI_PAST_DATA : AdaActionsTypes.GET_ADA_MULTI_DATA,
  payload,
});

export const getAdaMultiGraphData = (payload) => ({
  type: AdaActionsTypes.GET_ADA_MULTI_GRAPH_DATA,
  payload,
});

export const updateADAMultiData = (payload) => ({
  type: AdaActionsTypes.UPDATE_ADA_MULTI_DATA,
  payload,
});

export const updateForecast = (payload) => ({
  type: AdaActionsTypes.UPDATE_MULTI_FORECAST_MULTIPLIER,
  payload,
});


export const resetAll = () => ({
  type: AdaActionsTypes.RESET_ALL,
});
