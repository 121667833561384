export const GET_ADA_ALLOCATION_DATA_REQUEST = "GET_ADA_ALLOCATION_DATA_REQUEST";
export const GET_ADA_ALLOCATION_DATA_SUCCESS = "GET_ADA_ALLOCATION_DATA_SUCCESS";
export const GET_ADA_ALLOCATION_DATA_FAILED = "GET_ADA_ALLOCATION_DATA_FAILED";

export const GET_ADA_STANDALONE_DATA_REQUEST = "GET_ADA_STANDALONE_DATA_REQUEST";
export const GET_ADA_STANDALONE_DATA_SUCCESS = "GET_ADA_STANDALONE_DATA_SUCCESS";
export const GET_ADA_STANDALONE_DATA_FAILED = "GET_ADA_STANDALONE_DATA_FAILED";

export const SAVE_ADA_DATA_REQUEST = "SAVE_ADA_DATA_REQUEST";
export const SAVE_ADA_DATA_SUCCESS = "SAVE_ADA_DATA_SUCCESS";
export const SAVE_ADA_DATA_FAILED = "SAVE_ADA_DATA_FAILED";

export const SAVE_CHANGES_CA_VISUAL_REQUEST = "SAVE_CHANGES_CA_VISUAL_REQUEST";

export const REVIEW_ADA_DATA_REQUEST = "REVIEW_ADA_DATA_REQUEST";
export const REVIEW_ADA_DATA_SUCCESS = "REVIEW_ADA_DATA_SUCCESS";
export const REVIEW_ADA_DATA_FAILED = "REVIEW_ADA_DATA_FAILED";

export const GET_ADA_FILTER_DATA_REQUEST = "GET_ADA_FILTER_DATA_REQUEST";
export const GET_ADA_FILTER_DATA_SUCCESS = "GET_ADA_FILTER_DATA_SUCCESS";
export const GET_ADA_FILTER_DATA_FAILED = "GET_ADA_FILTER_DATA_FAILED";

export const GET_ADA_DRIVERS_DATA_REQUEST = 'GET_ADA_DRIVERS_DATA_REQUEST';
export const GET_ADA_DRIVERS_DATA_SUCCESS = 'GET_ADA_DRIVERS_DATA_SUCCESS';
export const GET_ADA_DRIVERS_DATA_FAILED = 'GET_ADA_DRIVERS_DATA_FAILED';

export const GET_ADA_HIERARCHY_ALLOCATION_DATA_REQUEST = 'GET_ADA_HIERARCHY_ALLOCATION_DATA_REQUEST'
export const GET_ADA_HIERARCHY_DATA_REQUEST = 'GET_ADA_HIERARCHY_DATA_REQUEST';
export const GET_ADA_HIERARCHY_DATA_SUCCESS = 'GET_ADA_HIERARCHY_DATA_SUCCESS';
export const GET_ADA_HIERARCHY_DATA_FAILED = 'GET_ADA_HIERARCHY_DATA_FAILED';

export const SET_CURR_FISCAL_YEARWEEK = "SET_CURR_FISCAL_YEARWEEK";
export const SET_STARTEND_FISCAL_YEARWEEK = "SET_STARTEND_FISCAL_YEARWEEK";
export const SET_ADA_ALLOCATION_REQBODY = "SET_ADA_ALLOCATION_REQBODY";
export const SET_ADA_DATA_TIME_CONSTRAINT = "SET_ADA_DATA_TIME_CONSTRAINT";
export const RESET_ADA_VISUAL = "RESET_ADA_VISUAL";
export const UPDATE_FORECAST_DATA = "UPDATE_FORECAST_DATA";
export const SET_STANDALONE_REQBODY = "SET_STANDALONE_REQBODY";
export const RESET_ADA_FILTER_DATA = "RESET_ADA_FILTER_DATA";
export const UPDATE_FORECAST_MULTIPLIER = "UPDATE_FORECAST_MULTIPLIER";
export const RESTORE_ADA_DATA = "RESTORE_ADA_DATA";
export const RESTORE_HIERARCHY_DATA = "RESTORE_HIERARCHY_DATA";
export const CHANGE_HIERARCHY = "CHANGE_HIERARCHY";
export const RESET_SELECTED_DATES = "RESET_SELECTED_DATES";

export const DOWNLOAD_CSV = "@@Ada/DOWNLOAD_CSV"
export const DOWNLOAD_CSV_SUCCESS = "@@Ada/DOWNLOAD_CSV_SUCCESS"
export const DOWNLOAD_CSV_ERROR = "@@Ada/DOWNLOAD_CSV_ERROR"


export const DOWNLOAD_EXCEL_LOWER = "@@Ada/DOWNLOAD_EXCEL_LOWER"
export const DOWNLOAD_EXCEL_LOWER_SUCCESS = "@@Ada/DOWNLOAD_EXCEL_LOWER_SUCCESS"
export const DOWNLOAD_EXCEL_LOWER_ERROR = "@@Ada/DOWNLOAD_EXCEL_LOWER_ERROR"

export const GET_ADA_STORE_HIERARCHY_DATA_REQUEST = "@@Ada/GET_ADA_STORE_HIERARCHY_DATA_REQUEST"
export const GET_ADA_STORE_HIERARCHY_DATA_SUCCESS = "@@Ada/GET_ADA_STORE_HIERARCHY_DATA_SUCCESS"
export const GET_ADA_STORE_HIERARCHY_DATA_FAILED = "@@Ada/GET_ADA_STORE_HIERARCHY_DATA_FAILED"

export const GENERATE_EXCEL = "@@Ada/GENERATE_EXCEL";
export const GENERATE_EXCEL_SUCCESS = "@@Ada/GENERATE_EXCEL_SUCCESS";
export const GENERATE_EXCEL_ERROR = "@@Ada/GENERATE_EXCEL_ERROR";
export const RESET_DOWNLOAD_EXCEL = "@@Ada/RESET_DOWNLOAD_EXCEL";

export const CLEAR_HIERARCHY_DATA  = "@@AdaCLEAR_HIERARCHY_DATA ";

export const RESET_ADA_VISUAL_DATA = "RESET_ADA_VISUAL_DATA";
