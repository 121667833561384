import React, { useEffect } from "react";
import SalesView from "../SalesView";
import ForecastEditView from "./ForecastEditView";
import { connect } from "react-redux";
import * as Notify from "../../../components/Notification/Notifications";

const MultiDeptView = (props) => {
  const {
    selectedFilters,
    actions,
    data,
    dataLoading,
    dataError,
    dataSuccess,
    nextIndex,
    totalCount,
    pastData,
    pastDataLoading,
    pastDataError,
    pastDataSuccess,
    pastDataCount,
    pastDataNextIndex,
    updateLoading,
    updateError,
    updateSuccess,
    graphData,
    graphSuccess,
    current_fw,
    accuracy,
    weeksData
  } = props;

  useEffect(() => {
    return () => {
      actions.resetAll();
    };
  }, []);

  return (
    <>
      {graphSuccess && (
        <>
          <div className="drivers_container shadow-sm">
            <SalesView
              adaVisualData={weeksData}
              adaGraphData={graphData}
              currentFiscalWeek={current_fw}
              isMulti={true}
              accuracy={accuracy}
              // adaAllocationReqBody= {props.adaAllocationReqBody}
              // isAdaAllocationModeSelected= {props.isAdaAllocationModeSelected}
              // adaStandAloneReqBody= {props.adaStandAloneReqBody}
              // accuracy={accuracy}
            />
          </div>

          <div className="drivers_container">
            <ForecastEditView
              pastData={true}
              actions={actions}
              selectedFilters={selectedFilters}
              data={pastData}
              dataLoading={pastDataLoading}
              dataError={pastDataError}
              nextIndex={pastDataNextIndex}
              totalCount={pastDataCount}
            />
          </div>
          <div className="drivers_container">
            <ForecastEditView
              data={data}
              dataLoading={dataLoading}
              dataError={dataError}
              nextIndex={nextIndex}
              totalCount={totalCount}
              actions={actions}
              selectedFilters={selectedFilters}
              updateLoading={updateLoading}
              updateError={updateError}
              updateSuccess={updateSuccess}
            />
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ adaMultiDept }) => ({
  data: adaMultiDept.data,
  dataLoading: adaMultiDept.dataLoading,
  dataError: adaMultiDept.dataError,
  dataSuccess: adaMultiDept.dataSuccess,
  nextIndex: adaMultiDept.nextIndex,
  totalCount: adaMultiDept.totalCount,
  pastData: adaMultiDept.pastData,
  pastDataLoading: adaMultiDept.pastDataLoading,
  pastDataError: adaMultiDept.pastDataError,
  pastDataSuccess: adaMultiDept.pastDataSuccess,
  pastDataCount: adaMultiDept.pastDataCount,
  pastDataNextIndex: adaMultiDept.pastDataNextIndex,
  updateLoading: adaMultiDept.updateLoading,
  updateError: adaMultiDept.updateError,
  updateSuccess: adaMultiDept.updateSuccess,
  graphData: adaMultiDept.graphData,
  graphSuccess: adaMultiDept.graphSuccess,
  accuracy: adaMultiDept.accuracy,
  weeksData: adaMultiDept.weeksData
});

const mapDispatchToProps = (dispatch) => ({
  // adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiDeptView);
