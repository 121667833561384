export const GET_FILTERS_ACTION = "@@ADA_MULTI_DEPT/GET_FILTERS_ACTION";
export const GET_FILTERS_ACTION_SUCCESS = "@@ADA_MULTI_DEPT/GET_FILTERS_ACTION_SUCCESS";
export const GET_FILTERS_ACTION_ERROR = "@@ADA_MULTI_DEPT/GET_FILTERS_ACTION_ERROR";

export const GET_ADA_MULTI_DATA = "@@ADA_MULTI_DEPT/GET_ADA_MULTI_DATA";
export const GET_ADA_MULTI_DATA_SUCCESS = "@@ADA_MULTI_DEPT/GET_ADA_MULTI_DATA_SUCCESS";
export const GET_ADA_MULTI_DATA_ERROR = "@@ADA_MULTI_DEPT/GET_ADA_MULTI_DATA_ERROR";

export const GET_ADA_MULTI_PAST_DATA = "@@ADA_MULTI_DEPT/GET_ADA_MULTI_PAST_DATA";
export const GET_ADA_MULTI_PAST_DATA_SUCCESS = "@@ADA_MULTI_DEPT/GET_ADA_MULTI_PAST_DATA_SUCCESS";
export const GET_ADA_MULTI_PAST_DATA_ERROR = "@@ADA_MULTI_DEPT/GET_ADA_MULTI_PAST_DATA_ERROR";

export const GET_ADA_MULTI_GRAPH_DATA = "@@ADA_MULTI_DEPT/GET_ADA_MULTI_GRAPH_DATA";
export const GET_ADA_MULTI_GRAPH_DATA_SUCCESS = "@@ADA_MULTI_DEPT/GET_ADA_MULTI_GRAPH_DATA_SUCCESS";
export const GET_ADA_MULTI_GRAPH_DATA_ERROR = "@@ADA_MULTI_DEPT/GET_ADA_MULTI_GRAPH_DATA_ERROR";

export const UPDATE_ADA_MULTI_DATA = "@@ADA_MULTI_DEPT/UPDATE_ADA_MULTI_DATA";
export const UPDATE_ADA_MULTI_DATA_SUCCESS = "@@ADA_MULTI_DEPT/UPDATE_ADA_MULTI_DATA_SUCCESS";
export const UPDATE_ADA_MULTI_DATA_ERROR = "@@ADA_MULTI_DEPT/UPDATE_ADA_MULTI_DATA_ERROR";

export const UPDATE_MULTI_FORECAST_MULTIPLIER = "UPDATE_MULTI_FORECAST_MULTIPLIER";


export const RESET_ALL = "@@ADA_MULTI_DEPT/RESET_ALL";