import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { initialState } from "./initialState";
import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";
import moment from "moment";

export default {
  adaMultiDept: handleActions(
    {
      [actionTypes.GET_FILTERS_ACTION]: (state, { payload }) => {
        return immutable(state, {});
      },
      [actionTypes.GET_FILTERS_ACTION_SUCCESS]: (state, payload) => {
        return immutable(state, {
          filtersData: {
            $merge: payload?.data ? payload.data : {},
          },
          filterLabels: {
            $set:
              payload.data && payload.data.filterMapping
                ? payload.data.filterMapping
                : state.filterMapping,
          },
        });
      },
      [actionTypes.GET_FILTERS_ACTION_ERROR]: (state, { payload }) => {
        return immutable(state, {
          filtersData: { $set: {} },
        });
      },
      [actionTypes.GET_ADA_MULTI_DATA]: (state, { payload }) => {
        return immutable(state, {
          data: { $set: [] },
          dataLoading: { $set: true },
          dataError: { $set: null },
          dataSuccess: { $set: false },
        });
      },
      [actionTypes.GET_ADA_MULTI_DATA_SUCCESS]: (state, payload) => {
        return immutable(state, {
          data: { $set: (payload && payload.data) || [] },
          dataLoading: { $set: false },
          dataError: { $set: null },
          dataSuccess: { $set: true },
          totalCount: { $set: (payload && payload.totalCount) || 0 },
          nextIndex: { $set: (payload && payload.nextIndex) || 0 },
        });
      },
      [actionTypes.GET_ADA_MULTI_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          data: { $set: [] },
          dataLoading: { $set: false },
          dataError: { $set: payload && payload.error },
          dataSuccess: { $set: true },
        });
      },
      [actionTypes.GET_ADA_MULTI_PAST_DATA]: (state, { payload }) => {
        return immutable(state, {
          pastData: { $set: [] },
          pastDataLoading: { $set: true },
          pastDataError: { $set: null },
          // dataSuccess: { $set: false },
        });
      },
      [actionTypes.GET_ADA_MULTI_PAST_DATA_SUCCESS]: (state, payload) => {
        return immutable(state, {
          pastData: { $set: (payload && payload.data) || [] },
          pastDataLoading: { $set: false },
          pastDataError: { $set: null },
          pastDataSuccess: { $set: true },
          pastDataCount: { $set: (payload && payload.totalCount) || 0 },
          pastDataNextIndex: { $set: (payload && payload.nextIndex) || 0 },
        });
      },
      [actionTypes.GET_ADA_MULTI_PAST_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          pastData: { $set: [] },
          pastDataLoading: { $set: false },
          pastDataError: { $set: payload && payload.error },
          pastDataSuccess: { $set: true },
        });
      },
      [actionTypes.UPDATE_ADA_MULTI_DATA]: (state, { payload }) => {
        return immutable(state, {
          updateLoading: { $set: true },
          updateError: { $set: null },
          updateSuccess: { $set: false },
        });
      },
      [actionTypes.UPDATE_ADA_MULTI_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          updateLoading: { $set: false },
          updateError: { $set: null },
          updateSuccess: { $set: true },
        });
      },
      [actionTypes.UPDATE_ADA_MULTI_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          updateLoading: { $set: false },
          updateError: { $set: payload && payload.error },
          updateSuccess: { $set: false },
        });
      },
      [actionTypes.GET_ADA_MULTI_GRAPH_DATA]: (state, { payload }) => {
        return immutable(state, {
          graphData: { $set: [] },
          graphLoading: { $set: true },
          graphError: { $set: null },
          graphSuccess: { $set: false },
          accuracy: { $set: {} },
          weeksData: { $set: [] },
          updateLoading: { $set: false },
          updateError: { $set: null },
          updateSuccess: { $set: false },
          data: { $set: [] },
          dataLoading: { $set: false },
          dataError: { $set: null },
          dataSuccess: { $set: false },
          totalCount: { $set: 0 },
          nextIndex: { $set: 0 },
          pastData: { $set: [] },
          pastDataLoading: { $set: false },
          pastDataError: { $set: null },
          pastDataSuccess: { $set: false },
          pastDataCount: { $set: 0 },
          pastDataNextIndex: { $set: 0 },
          updateLoading: { $set: false },
          updateError: { $set: null },
          updateSuccess: { $set: false },
        });
      },
      [actionTypes.GET_ADA_MULTI_GRAPH_DATA_SUCCESS]: (state, payload) => {
        return immutable(state, {
          graphData: { $set: (payload && payload.adaGraphData) || [] },
          weeksData: { $set: (payload && payload.weeksData) || [] },
          graphLoading: { $set: false },
          graphError: { $set: null },
          graphSuccess: { $set: true },
          accuracy: { $set: (payload && payload.accuracy) || {} },
        });
      },
      [actionTypes.GET_ADA_MULTI_GRAPH_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          graphData: { $set: [] },
          weeksData: { $set: [] },
          graphLoading: { $set: false },
          graphError: { $set: payload && payload.error },
          graphSuccess: { $set: true },
          accuracy: { $set: {} },
        });
      },
      [actionTypes.UPDATE_MULTI_FORECAST_MULTIPLIER]: (state, { payload }) => {
        const { forecastValue, current_fw } = payload;
        const updateAdaData = cloneDeep(state.weeksData);

        const updatedGraphData = {
          labels_data: [],
          actual_data: [],
          ia_data: [],
          year: [],
          adjusted_data: [],
          ly_sales: [],
        };

        updateAdaData.forEach((item) => {
          if (item.fy_fw >= current_fw) {
            item["forecast_multiplier"] = forecastValue;
            item["adjusted_forecast"] = forecastValue * item["ia_forecast"];
          } else {
            //No code here
          }
        });

        updateAdaData.forEach((item) => {
          updatedGraphData.labels_data.push(
            `W${item.week}(${moment(item.date).format("MM-DD")})`
          );
          updatedGraphData.ia_data.push(item.ia_forecast);
          updatedGraphData.ly_sales.push(item.ly_sales);
          if (item.fy_fw < payload.current_fw) {
            updatedGraphData.actual_data.push(item.actual_sales);
            // item.fy_fw == payload.current_fw ?
            updatedGraphData.adjusted_data.push(item.adjusted_forecast);
            // : updatedGraphData.adjusted_data.push(undefined);
          } else {
            updatedGraphData.adjusted_data.push(item.adjusted_forecast);
            updatedGraphData.year.push(item.year);
          }
        });

        return immutable(state, {
          graphData: { $set: updatedGraphData },
          isForecastModified: { $set: true },
          weeksData: { $set: updateAdaData },
        });
      },
      [actionTypes.RESET_ALL]: (state) => {
        return immutable(state, {
          filtersData: { $set: {} },
          filterLabels: { $set: {} },
          data: { $set: [] },
          dataLoading: { $set: false },
          dataError: { $set: null },
          dataSuccess: { $set: false },
          totalCount: { $set: 0 },
          nextIndex: { $set: 0 },
          pastData: { $set: [] },
          pastDataLoading: { $set: false },
          pastDataError: { $set: null },
          pastDataSuccess: { $set: false },
          pastDataCount: { $set: 0 },
          pastDataNextIndex: { $set: 0 },
          updateLoading: { $set: false },
          updateError: { $set: null },
          updateSuccess: { $set: false },
          graphData: { $set: [] },
          graphLoading: { $set: false },
          graphError: { $set: null },
          graphSuccess: { $set: false },
          accuracy: { $set: {} },
          weeksData: { $set: [] },
        });
      },
    },

    initialState
  ),
};
