import React, { useEffect, useState } from "react";
import MultiSelect from "../../../components/Filters/MultiSelect";
import Select from "../../../components/Filters/SingleSelect";
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { intersectionWith, isEqual } from "lodash";
import * as adaVisualActions from "./actions/Actions";
import * as Notify from "../../../components/Notification/Notifications";
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping";
import "../AdaVisual.css";
import {
  getUserPreferenceFilter,
  shouldHideForCurrentClient,
  setUserPreferenceFilter,
  getFilterForClients,
  isMandatory,
  getLevelFiltersForUserPreference,
  getDefaultFiltersCondition,
} from "../../../utils/commonUtilities";

/**
 * View Component with Ada Stand Alone Filters
 */
const Filters = React.memo((props) => {
  const [level1, setLevel1] = useState(null);
  const [level2, setLevel2] = useState(null);
  const [level3, setLevel3] = useState(null);
  const [subClass, setSubClass] = useState(null);
  const [store, setStore] = useState(null);
  const [week, setWeek] = useState(null);
  //   const [level1Options, setLevel1Options] = useState(null);
  //   const [level2Options, setLevel2Options] = useState(null);
  //   const [level3Options, setLevel3Options] = useState(null);
  //   const [subClassOptions, setSubClassOptions] = useState(null);
  //   const [storeOptions, setStoreOptions] = useState(null);
  //   const [weekOptions, setWeekOptions] = useState(null);

  const {
    adaVisualActions,
    handleFilterRequest,
    resetAdaDisplayState,
    filtersData,
  } = props;

  useEffect(() => {
    adaVisualActions.getAdaFiltersData({});
  }, []);

  //   useEffect(() => {
  //     setLevel1Options(() => props.level1Options);
  //   }, [props.level1Options]);

  //   useEffect(() => {
  //     setLevel2Options(() => props.level2Options);
  //   }, [props.level2Options]);

  //   useEffect(() => {
  //     setLevel3Options(() => props.level3Options);
  //   }, [props.level3Options]);

  //   useEffect(() => {
  //     setSubClassOptions(() => props.subClassOptions);
  //   }, [props.subClassOptions]);

  //   useEffect(() => {
  //     setWeekOptions(() => props.weekOptions);
  //   }, [props.weekOptions]);

  //   useEffect(() => {
  //     setStoreOptions(() => props.storeOptions);
  //   }, [props.storeOptions]);

  const resetRestFilters = (p_index) => {
    if (p_index == 0) {
      level2 && setLevel2(null);
      //   level2Options && setLevel2Options(null);
      week && setWeek(null);
      store && setStore(null);
    }
    if (p_index == 0 || p_index == 1) {
      level3 && setLevel3(null);
      //   level3Options && setLevel3Options(null);
      week && setWeek(null);
      store && setStore(null);
    }
    if (p_index == 0 || p_index == 1 || p_index == 2) {
      subClass && setSubClass(null);
      //   subClassOptions && setSubClassOptions(null);
      week && setWeek(null);
      store && setStore(null);
    }
    if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3) {
      week && setWeek(null);
      store && setStore(null);
    }
    if (p_index === 4) {
      week && setWeek(null);
      store && setStore(null);
    }
    if (p_index === 5) {
      week && setWeek(null);
      store && setStore(null);
    }
  };

  const onLevel1Change = (val, p_userPreference) => {
    setLevel1(val);
    if (p_userPreference?.user_preference) {
      adaVisualActions.getAdaFiltersData({ level1: [val] }, "l10");
    } else {
      adaVisualActions.getAdaFiltersData({ level1: [val] });
    }
    resetRestFilters(0);
  };

  const onLevel2Change = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(
        valuesArray[0],
        filtersData.l2_name,
        isEqual
      );
      setLevel2(valuesArray[1]);
      if (event?.user_preference) {
        adaVisualActions.getAdaFiltersData({
          level1: [event?.l1_name],
          level2: Array.isArray(val) ? val : [val],
        });
      } else {
        adaVisualActions.getAdaFiltersData({
          level1: [level1],
          level2: req_val,
        });
      }
      // adaVisualActions.getAdaFiltersData({ level1: [department], level2: [...req_val] });
    } else {
      setLevel2(val);
      if (event?.user_preference) {
        adaVisualActions.getAdaFiltersData(
          { level1: [event?.l1_name], level2: [val] },
          "l11"
        );
      } else {
        adaVisualActions.getAdaFiltersData({
          level1: [level1],
          level2: [val],
        });
      }
    }
    resetRestFilters(1);
  };

  const onLevel3Change = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(
        valuesArray[0],
        filtersData.l3_name,
        isEqual
      );
      setLevel3(valuesArray[1]);
      adaVisualActions.getAdaFiltersData({
        level1: [level1],
        level2: Array.isArray(level2) ? level2 : [level2],
        level3: req_val && [...req_val],
      });
    } else {
      setLevel3(val);
      if (event?.user_preference) {
        adaVisualActions.getAdaFiltersData({
          level1: [event?.l1_name],
          level2: [event?.l2_name],
          level3: [val],
        });
      } else {
        adaVisualActions.getAdaFiltersData({
          level1: [level1],
          level2: level2,
          level3: [val],
        });
      }
    }
    resetRestFilters(2);
  };

  const onSubClassChange = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(
        valuesArray[0],
        filtersData.subClass,
        isEqual
      );
      setSubClass(valuesArray[1]);
      adaVisualActions.getAdaFiltersData({
        level1: [level1],
        level2: Array.isArray(level2) ? level2 : [level2],
        level3: level3 && [...level3],
        level4: [...req_val],
      });
    } else {
      setSubClass(val);
      adaVisualActions.getAdaFiltersData(
        {
          level1: [level1],
          level2: level2,
          level3: [level3],
          level4: [val],
        },
        "level5"
      );
    }
    resetRestFilters(3);
  };

  const onStoreChange = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(
        valuesArray[0],
        filtersData.store,
        isEqual
      );
      setWeek(null);
      setStore(valuesArray[1]);
      adaVisualActions.getAdaFiltersData({
        level1: [level1],
        level2: Array.isArray(level2) ? level2 : [level2],
        level3: level3 && [...level3],
        subClass: subClass && [...subClass],
        store: [...req_val],
      });
    } else {
      setStore(val);
      adaVisualActions.getAdaFiltersData(
        {
          level1: [level1],
          level2: level2,
          level3: [level3],
          store: [val],
        },
        "level7"
      );
    }
    //resetRestFilters(5);
  };

  const onWeekChange = (val, event, that) => {
    if (Array.isArray(val)) {
      setWeek(null);
      let valuesArray = handleSelectAll(val, event, that);
      //   let req_val = intersectionWith(valuesArray[0], filtersData.week, isEqual);
      setWeek(valuesArray[1]);
      // adaVisualActions.getAdaFiltersData({
      //     level1: [department], level2: [...gender], level3: subCat ? [...subCat] : null,
      //     level4: dcs ? [...dcs] : null, article: [...req_val],
      // });
    } else {
      setWeek(val);
      // adaVisualActions.getAdaFiltersData({
      //     level1: [department], level2: [gender], level3: [subCat], level4: [dcs],
      //     article: [val],
      // });
    }
    // resetRestFilters(4);
  };

  const applyFilters = () => {
    if (getDefaultFiltersCondition({ l1_name: level1, l2_name: level2 })) {
      handleFilterRequest({
        level1: [level1],
        level2,
        level3,
        subClass,
        store,
        week,
        row_index: 0
      });
      // setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": department });
      setUserPreferenceFilter(
        { user_id: localStorage.getItem("user_id") },
        getLevelFiltersForUserPreference({
          l1_name: level1,
          l2_name: level2,
        })
      );
    } else {
      Notify.error("Please Select All Mandatory Options!!");
    }
  };

  const resetFilters = () => {
    setLevel1(null);
    setLevel2(null);
    setLevel3(null);
    setSubClass(null);
    resetAdaDisplayState();
    setWeek(null);
    setStore(null);
  };

  useEffect(() => {
    // getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
    getUserPreferenceFilter(
      { user_id: localStorage.getItem("user_id") },
      { onl1Change: onLevel1Change, onl2Change: onLevel2Change }
    );
  }, []);

  const getDDLabel = (label) => (label ? label : "");

  return (
    <>
      <section className="row section" style={{flex:"auto"}}>
        {/* <Notification /> */}
        <div className="col-md-3 col-md-3-dd required">
          <label className="fnt-md fnt-bold fnt-bold">
            {levelFiltersLabelFormatter("level1")}
          </label>
          <Select
            name="level1"
            value={level1}
            onChange={onLevel1Change}
            placeHolder={`Select ${levelFiltersLabelFormatter("level1")}`}
            options={filtersData?.l1_name?.length ? filtersData.l1_name : []}
          />
        </div>
        <div className="col-md-3 col-md-3-dd required">
          <label className="fnt-md fnt-bold">
            {props.filterLabels?.level2}
          </label>
          <MultiSelect
            name="level2"
            dropdownValue={level2}
            onDropDownChange={onLevel2Change}
            placeHolder={`Select ${props.filterLabels?.level2}`}
            optionsArray={
              filtersData?.l2_name?.length ? filtersData.l2_name : []
            }
          />
        </div>
        {!shouldHideForCurrentClient("l3_name") && (
          <>
            <div className="col-md-3 fill col-md-3-dd">
              <label className="fnt-md fnt-bold">
                {props.filterLabels?.level3}
              </label>
              <MultiSelect
                name="level3"
                dropdownValue={level3}
                onDropDownChange={onLevel3Change}
                placeHolder={`Select ${props.filterLabels?.level3}`}
                optionsArray={
                  filtersData?.l3_name?.length ? filtersData.l3_name : []
                }
              />
            </div>
            <div className="col-md-3 fill col-md-3-dd">
              <label className="fnt-md fnt-bold">Sub Class</label>
              <MultiSelect
                name="subClass"
                dropdownValue={subClass}
                onDropDownChange={onSubClassChange}
                placeHolder={`Select Sub Class`}
                optionsArray={
                  filtersData?.subclass_name?.length
                    ? filtersData.subclass_name
                    : []
                }
              />
            </div>
          </>
        )}
        <div className="col-md-3 col-md-3-dd">
          <label className="fnt-md fnt-bold">Store</label>
          <MultiSelect
            name="store_code"
            dropdownValue={store}
            onDropDownChange={onStoreChange}
            placeHolder={"Select Store"}
            optionsArray={
              filtersData?.store_code?.length ? filtersData.store_code : []
            }
            isBulkSearch
          />
        </div>
        <div className="col-md-3 col-md-3-dd">
          <label className="fnt-md fnt-bold">Week</label>
          <MultiSelect
            name="assortment_indicator"
            dropdownValue={week}
            onDropDownChange={onWeekChange}
            placeHolder={"Select Fiscal Week"}
            optionsArray={filtersData?.weeks?.length ? filtersData.weeks : []}
          />
        </div>
        <div className="col-md-3 fill col-md-3-dd">
          <button
            onClick={applyFilters}
            className="btn btn-primary filter-constraints-mode"
            title="Apply filters"
          >
            <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
          </button>
          <button
            onClick={() => resetFilters("All")}
            className="btn undo-constraints-mode"
            title="Reset filters"
          >
            <i className="fa fa-undo mr-2"></i>Reset
          </button>
        </div>
        <div className="required fnt-md pt-5 adaVisuals-redText">
          <label></label>Fields are mandatory
        </div>
      </section>
    </>
  );
});

const mapStateToProps = ({ adaMultiDept }) => ({
  filtersData: adaMultiDept.filtersData,
  filterLabels: adaMultiDept.filterLabels,
});

const mapDispatchToProps = (dispatch) => ({
  adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
