import React, { useState, useEffect, useCallback } from "react";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable";
import { cloneDeep, isEmpty } from "lodash";
import { connect } from "react-redux";
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping";
import ColumnFilterServerSide from "../../../components/Filters/ColumnFilterServerSide";
import NumberRangeColumnFilterServerSide from "../../../components/Filters/NumberRangeColumnFilterServerSide";
import {
  removeCheckedArticles,
  getNewData,
} from "../../../utils/paginationHelper";
import InputCell from "../../../components/Table/Cellrenderer/InputCell";
import PageLoader from "../../../components/Loader/PageLoader";
import * as Notify from "../../../components/Notification/Notifications";
import SetAllModal from "../../../components/ErrorModal/SetAllModal";
import { getAdaMultiDeptData } from "../../../routes/api";
import ParallelExcelDownload from "../../../components/ParallelExcelDownload/ParallelExcelDownload";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ForecastEditView = (props) => {
  const {
    actions,
    data,
    dataLoading,
    selectedFilters,
    pastData,
    updateLoading,
    updateError,
    updateSuccess,
  } = props;

  const [tableData, setTableData] = useState([]);
  const [RTInstance, setRTInstance] = useState({});
  const [nextIndex, setNextIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [initialIndex, setInitialIndex] = useState(0);
  const [searchTermReq, setSearchTermReq] = useState({});
  const [sortReq, setSortReq] = useState({});
  const [isFirstCall, setisFirstCall] = useState(true);
  const [prevAction, setPrevAction] = useState("");
  const [checkedRows, setCheckedRows] = useState([]);
  const [checkAll, setCheckAll] = useState([]);
  // const [checkAllReqArr, setCheckAllReqArr] = useState([]);
  const [initialSelectedRows, setInitialSelectedRows] = useState({});
  const [updatedRows, setUpdatedRows] = useState([]);
  const [showSetAll, setShowSetAll] = useState(false);
  const [multiplier, setMultiplier] = useState(null);

  const columns = React.useMemo(() => [
    {
      Header: levelFiltersLabelFormatter("level1"),
      accessor: "l1_name",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.l1_name?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
    },
    {
      Header: levelFiltersLabelFormatter("level2"),
      accessor: "l2_name",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.l2_name?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
    },
    {
      Header: levelFiltersLabelFormatter("level3"),
      accessor: "l3_name",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.l3_name?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
    },
    {
      Header: "Sub Class",
      accessor: "subclass",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.subclass?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
    },
    {
      Header: "Store",
      accessor: "store",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.store?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
    },
    {
      Header: "Week",
      accessor: "fy_fw",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.fy_fw?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
    },
    {
      Header: "IA Forecast",
      accessor: "ia_forecast",
      Filter: (instance) => (
        <NumberRangeColumnFilterServerSide
          {...instance}
          minSearchTermValue={searchTermReq?.ia_forecast?.value?.min}
          maxSearchTermValue={searchTermReq?.ia_forecast?.value?.max}
          clickHandler={(min, max, id) =>
            fecthDataWithSearchTerm([min, max], id, "range")
          }
        />
      ),
    },
    {
      Header: "Actual",
      accessor: "actual_sales",
      Filter: (instance) => (
        <NumberRangeColumnFilterServerSide
          {...instance}
          minSearchTermValue={searchTermReq?.actual_sales?.value?.min}
          maxSearchTermValue={searchTermReq?.actual_sales?.value?.max}
          clickHandler={(min, max, id) =>
            fecthDataWithSearchTerm([min, max], id, "range")
          }
        />
      ),
    },
    {
      Header: "Multiplier",
      accessor: "multiplier",
      Cell: (instance) =>
        !pastData && instance.row.original.ia_forecast ? (
          <InputCell
            isFloor
            min={0}
            step={1}
            type="number"
            {...instance}
            blurHandler={(rowIdx, columnId, values) => {
              setUpdatedRows((old) => {
                return [...old, instance.row.original.unique];
              });
              instance.updateMyData(
                rowIdx,
                "adjusted_forecast",
                instance.row.original.ia_forecast * Number(values)
              );
            }}
          />
        ) : (
          instance.value
        ),
      Filter: (instance) => (
        <NumberRangeColumnFilterServerSide
          {...instance}
          minSearchTermValue={searchTermReq?.multiplier?.value?.min}
          maxSearchTermValue={searchTermReq?.multiplier?.value?.max}
          clickHandler={(min, max, id) =>
            fecthDataWithSearchTerm([min, max], id, "range")
          }
        />
      ),
    },
    {
      Header: "Adjusted Forecast",
      accessor: "adjusted_forecast",
      Cell: (instance) =>
        !pastData && instance.row.original.ia_forecast ? (
          <InputCell
            shouldOnBlur
            isFloor
            min={0}
            step={1}
            type="number"
            {...instance}
            blurHandler={(rowIdx, columnId, values) => {
              setUpdatedRows((old) => {
                return [...old, instance.row.original.unique];
              });
              instance.updateMyData(
                rowIdx,
                "multiplier",
                Number(values) / instance.row.original.ia_forecast
              );
            }}
          />
        ) : (
          instance.value
        ),
      Filter: (instance) => (
        <NumberRangeColumnFilterServerSide
          {...instance}
          minSearchTermValue={searchTermReq?.adjusted_forecast?.value?.min}
          maxSearchTermValue={searchTermReq?.adjusted_forecast?.value?.max}
          clickHandler={(min, max, id) =>
            fecthDataWithSearchTerm([min, max], id, "range")
          }
        />
      ),
    },
  ]);

  useEffect(() => {
    if (data?.length) {
      if (RTInstance?.data?.length) {
        let l_newData = getNewData(
          {
            p_newData: [...RTInstance?.data, ...props?.data],
            p_checkAll: checkAll,
          },
          "unique"
        );
        setInitialSelectedRows(l_newData?.finalCheckedRows);
        setTableData([...RTInstance.data, ...data]);
      } else {
        setTableData(data);
        setInitialSelectedRows({});
        setCheckAll([]);
        setPrevAction("");
      }
    }
  }, [data]);

  const fetchData = (index) => {
    if (prevIndex >= index || nextIndex >= totalCount) return;
    setPrevIndex(index);
    setInitialIndex(Math.floor(nextIndex / 10));
    actions.getAdaMultiData({
      ...selectedFilters,
      row_index: nextIndex,
      row_count: 1000,
      searchColumns: searchTermReq,
      sortColumn: sortReq,
      past_flag: !!pastData,
    });
  };

  useEffect(() => {
    if (props.nextIndex == 100) {
      setPrevIndex(0);
      // setInitialIndex(0)
    }
    setTotalCount(props.totalCount);
    setNextIndex(props.nextIndex);
  }, [props.totalCount, props.nextIndex]);

  useEffect(() => {
    if (
      (!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) &&
      !isEmpty(selectedFilters)
    ) {
      setTableData([]);
      setRTInstance(null);
      setInitialIndex(0);
      setPrevIndex(0);
      actions.getAdaMultiData({
        ...selectedFilters,
        row_index: 0,
        row_count: 1000,
        searchColumns: searchTermReq,
        sortColumn: sortReq,
        past_flag: !!pastData,
      });
    }
  }, [searchTermReq, sortReq]);

  const updateSetAll = (value) => {
    let l_newAction = {
      searchTermReq: searchTermReq,
    };
    if (!value.checked) {
      setPrevAction("checkAll");
      l_newAction["checkAll"] = true;
    } else {
      setPrevAction("unCheckAll");
      l_newAction["unCheckAll"] = true;
      let l_updatedCheckedRows = removeCheckedArticles(
        checkedRows,
        searchTermReq
      );
      setCheckedRows(l_updatedCheckedRows);
    }
    setCheckAll((old) => [...old, l_newAction]);
  };

  const updateCheckedRows = (value) => {
    let l_newAction = {},
      l_article = value.instance.unique;

    if (!value.checked) {
      setPrevAction("checkRow");
      if (prevAction === "checkRow") {
        let checkAllCopy = [...checkAll];
        let l_lastValueIndex =
          checkAllCopy.length - 1 === -1 ? 0 : checkAllCopy.length - 1;
        let l_checekdRows = checkAllCopy?.[l_lastValueIndex];
        l_checekdRows && l_checekdRows["checkedRows"]?.push(l_article);
        checkAllCopy.pop();
        l_checekdRows
          ? setCheckAll([...checkAllCopy, l_checekdRows])
          : setCheckAll([...checkAllCopy]);
      } else {
        l_newAction["checkedRows"] = [l_article];
        setCheckAll((old) => [...old, l_newAction]);
      }

      let l_checkedRow = { [l_article]: value.instance };
      setCheckedRows((old) => {
        return {
          ...old,
          ...l_checkedRow,
        };
      });
    } else {
      setPrevAction("unCheckRow");
      if (prevAction === "unCheckRow") {
        let checkAllCopy = [...checkAll];
        let l_lastValueIndex =
          checkAllCopy.length - 1 === -1 ? 0 : checkAllCopy.length - 1;
        let l_checekdRows = checkAllCopy[l_lastValueIndex];
        l_checekdRows["unCheckedRows"].push(l_article);
        checkAllCopy.pop();
        setCheckAll([...checkAllCopy, l_checekdRows]);
      } else {
        l_newAction["unCheckedRows"] = [l_article];
        setCheckAll((old) => [...old, l_newAction]);
      }

      let l_checkedRow = cloneDeep(checkedRows);
      delete l_checkedRow[l_article];
      setCheckedRows((old) => {
        return {
          ...l_checkedRow,
        };
      });
    }
  };

  const fecthDataWithSorting = (p_sort) => {
    if (!isEmpty(sortReq)) {
      isFirstCall && setisFirstCall(false);
    }
    setSortReq(p_sort[0]);
  };

  const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
    if (p_type === "array") {
      if (split_type) {
        p_term = p_term.replace(/\s*,\s*/g, ",");
      }
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        var id = p_term.split(split_type ? split_type : " ");
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            values: id,
            search: p_term,
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    } else if (Array.isArray(p_term)) {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        let min = p_term[0] == "" ? null : p_term[0];
        let max = p_term[1] == "" ? null : p_term[1];
        if (min == null && max == null) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            value: {
              min: min,
              max: max,
            },
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    } else {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            value: p_term,
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    }
    isFirstCall && setisFirstCall(false);
  };

  const resetState = () => {
    setTableData([]);
    setSearchTermReq({});
    setSortReq({});
    setRTInstance(null);
    setInitialIndex(0);
    setPrevIndex(0);
    // setTotalCount(0);
    setNextIndex(0);
    setInitialSelectedRows({});
    setCheckAll([]);
    // setCheckAllReqArr([]);
    setPrevAction("");
    setUpdatedRows([]);
  };

  useEffect(() => {
    return () => {
      setTableData([]);
      setSearchTermReq({});
      setSortReq({});
      setRTInstance(null);
      setInitialIndex(0);
      setPrevIndex(0);
      setTotalCount(0);
      setNextIndex(0);
      setInitialSelectedRows({});
      setCheckAll([]);
      // setCheckAllReqArr([]);
      setPrevAction("");
    };
  }, []);

  const updateData = (setAll) => {
    const req = {
      ...selectedFilters,
      searchColumns: searchTermReq,
      sortColumn: sortReq,
      past_flag: !!pastData,
    };
    if (setAll) {
      req["set_all"] = true;
      req["multiplier"] = multiplier;
    } else {
      const uniqueUpdatedRows = [...new Set(updatedRows)];
      const updatedData = RTInstance?.data?.filter((val) =>
        uniqueUpdatedRows.includes(val.unique)
      );
      req["data"] = updatedData;
    }
    setUpdatedRows([]);
    actions.updateADAMultiData(req);
  };

  // useEffect(() => {
  //   resetState();
  // }, [selectedFilters]);

  const setAllHandler = (p_instance) => {
    if (Object.keys(p_instance.state.selectedRowIds).length > 0) {
      setShowSetAll(true);
      setMultiplier(null);
    } else {
      Notify.error(
        `Please select at least 1 row to use Set All functionality!!`
      );
    }
  };

  const handleSetAllCancel = () => {
    setShowSetAll(false);
  };

  const handleClose = () => {
    showSetAll && setShowSetAll(false);
  };

  const handleSetAllOk = () => {
    let setAllRows = Object.keys(RTInstance.state.selectedRowIds);
    setTableData((oldData) => {
      let newData = cloneDeep(oldData);
      setAllRows.forEach((setAllRow) => {
        updatedRows.push(newData[setAllRow]["unique"]);
        if (
          (multiplier || multiplier == 0) &&
          Number(newData[setAllRow].ia_forecast)
        ) {
          newData[setAllRow]["multiplier"] = multiplier;
          newData[setAllRow]["adjusted_forecast"] =
            newData[setAllRow].ia_forecast * multiplier;
        }
      });
      return newData;
    });
    const l_lastValueIndex =
      checkAll?.length - 1 === -1 ? 0 : checkAll?.length - 1;
    setTimeout(() => {
      updateData(checkAll.length && checkAll[l_lastValueIndex]?.checkAll);
    }, 300)
  };

  const prepInput = React.useMemo(
    () => [
      {
        id: 3,
        isRequired: false,
        label: "Multiplier",
        name: "multiplier",
        className: "setAll__input__center_3",
        value: multiplier,
        onChange: (e) => {
          let val = e.target.value;
          if (Number(val) < 0) {
            val = 0;
          }
          val = Math.floor(Number(val) * 100) / 100;
          setMultiplier(val);
        },
        input: true,
        type: "number",
        min: 0,
      },
    ],
    [multiplier]
  );

  useEffect(() => {
    if (updateError) {
      Notify.error("Error in Saving Update!!");
    }
    if (updateSuccess) {
      // getData();
      setInitialSelectedRows({});
      setUpdatedRows([]);
      Notify.success("Updated Successfully!!");
    }
  }, [updateSuccess, updateError]);

  const formatExcelData = (data) => {
    const excelData = cloneDeep(data);
    return excelData.map((item) => {
      item.ia_forecast = Number(item.ia_forecast);
      item.adjusted_forecast = Number(item.adjusted_forecast)
      const obj = {
        Channel: item.l1_name,
        Department: item.l2_name,
        Class: item.l3_name,
        "Sub Class": item.subclass,
        Store: item.store,
        Week: item.fy_fw,
        "IA Forecast": (item.ia_forecast && item.ia_forecast % 1) ? item?.ia_forecast?.toFixed(2): item.ia_forecast,
        Actual: item.actual_sales,
        Multiplier: item.multiplier,
        "Adjusted Forecast": (item.adjusted_forecast && item.adjusted_forecast % 1) ? item.adjusted_forecast.toFixed(2): item.adjusted_forecast,
      };
      pastData && delete obj["Multiplier"];
      return obj
    });
  };

  return (
    <PageLoader loader={dataLoading || updateLoading} gridLoader={true}>
      {showSetAll && (
        <SetAllModal
          filter={prepInput}
          messageStyle={{ margin: "auto", color: "red" }}
          handleOk={handleSetAllOk}
          handleCancel={handleSetAllCancel}
          handleClose={() => handleClose()}
          disableApply={multiplier === null}
        />
      )}
      <h3 className="font-weight-bold mx-auto my-10 p-3">
        {pastData ? "Past Weeks" : "Current and Future Weeks"}
      </h3>
      {tableData?.length > 0 && (
        <ParallelExcelDownload
          apiURL={getAdaMultiDeptData}
          filters={selectedFilters}
          formatExcelData={formatExcelData}
          existingData={tableData}
          // excelColumnsToBeFormatted={EXCESS_INV_COLUMNS_FORMATTING}
          searchTermReq={searchTermReq}
          sortReq={sortReq}
          totalCount={totalCount}
          nextIndex={nextIndex}
          fileName={`ada_${
            pastData ? "past_weeks_" : ""
          }${new Date().getTime()}`}
          additionalInputKeys={["past_flag"]}
          past_flag={!!pastData}
        />
      )}
      <ReactTableWithPlugins
        shouldPagination
        totalRecordsLen={tableData?.length}
        data={tableData ? tableData : []}
        columns={columns}
        renderMarkup="TableMarkup"
        keyRT={pastData ? "sortAndSearch" : "StrategyPageTable"}
        features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
        fetchData={(data) => fetchData(data)}
        getInstanceOnMount={(instance) => {
          setRTInstance(instance);
        }}
        tableId="ada_multi"
        getManualSortByOptions={(instance) => {
          fecthDataWithSorting(instance);
        }}
        manualSortBy={true}
        initialPageIndex={initialIndex}
        totalCount={totalCount}
        checkAllCallBack={(val) => updateSetAll(val)}
        checkCallBack={(val) => updateCheckedRows(val)}
        initialSelectedRows={initialSelectedRows}
        hideDropdown
        initialHiddenColumns={pastData ? ["multiplier"] : []}
      />
      {!pastData && (
        <div className="text-center">
          <button
            disabled={updatedRows.length === 0}
            onClick={() => updateData()}
            class="btn btn-primary center m-2"
            title="Apply All filters"
          >
            Save
          </button>
          <button
            className="btn btn-primary m-2"
            onClick={() => setAllHandler(RTInstance)}
          >
            Set All
          </button>
        </div>
      )}
    </PageLoader>
  );
};

export default ForecastEditView;
