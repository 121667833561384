import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { adaVisualInitialState } from './initialState';
import { clone, cloneDeep, filter, forEach } from 'lodash';
import * as adaVisualActionTypes from "../actions/actionTypes";
import moment from "moment";

const adaVisualReducer = {
    adaVisual: handleActions(
        {
            [adaVisualActionTypes.SET_ADA_ALLOCATION_REQBODY]: (state, { payload }) => {
                return immutable(state, {
                    adaAllocationReqBody: { $set: payload },
                    isAdaAllocationModeSelected: { $set: true },
                });
            },
            [adaVisualActionTypes.GET_ADA_ALLOCATION_DATA_REQUEST]: (state, { payload }) => {
                return immutable(state, {
                    isAdaDataLoading: { $set: true },
                    isAdaReqFailed: { $set: false },
                    isAdaLoadingSuccess: { $set: false },
                    isAdaAllocationModeSelected: { $set: true },
                })
            },
            [adaVisualActionTypes.GET_ADA_ALLOCATION_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    adaVisualData: { $set: [...response.adaData] },
                    adaVisualDataCopy: { $set: [...response.adaData] },
                    adaGraphData: { $set: response.adaGraphData },
                    isAdaDataLoading: { $set: false },
                    isAdaReqFailed: { $set: false },
                    isAdaAllocationModeSelected: { $set: true },
                    isAdaLoadingSuccess: { $set: true },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    isForecastModified: { $set: false },
                    isHierarchyModified: { $set: false },
                });
            },
            [adaVisualActionTypes.GET_ADA_ALLOCATION_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isAdaReqFailed: { $set: true },
                    isAdaDataLoading: { $set: false },
                    errorMsg: { $set: error },
                    isAdaLoadingSuccess: { $set: false },
                    isAdaAllocationModeSelected: { $set: true },
                });
            },
            [adaVisualActionTypes.GET_ADA_STANDALONE_DATA_REQUEST]: (state, { payload }) => {
                return immutable(state, {
                    isAdaDataLoading: { $set: true },
                    isAdaReqFailed: { $set: false },
                    isAdaLoadingSuccess: { $set: false },
                    isAdaAllocationModeSelected: { $set: false },
                    // Resting the store level data on Filter Changes
                    storeHierarchyData: { $set: {} }, 
                    storeHierarchyDataCopy: { $set: {} },
                    updatedStoreData: { $set: {} },
                    updatedArticleWeek: { $set: {}},
                    updatedArticleData: { $set: {}},
                })
            },
            [adaVisualActionTypes.GET_ADA_STANDALONE_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    adaVisualData: { $set: [...response.adaData] },
                    adaVisualDataCopy: { $set: [...response.adaData] },
                    accuracy:{$set:response.accuracy},
                    adaGraphData: { $set: response.adaGraphData },
                    isAdaDataLoading: { $set: false },
                    isAdaReqFailed: { $set: false },
                    isAdaLoadingSuccess: { $set: true },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    isForecastModified: { $set: false },
                    isHierarchyModified: { $set: false },
                    downloadCsvData: { $set: [] },
                    downloadExcelLowerData: { $set: [] },
                    lowerHierarchyNextIndex: { $set:  0 },
                    totalRecords: { $set: 0 },
                    hierarchyData: { $set: [] },
                    hierarchyDataCopy: { $set: [] },
                    updatedForecastData: { $set: {} },
                });
            },
            [adaVisualActionTypes.GET_ADA_STANDALONE_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isAdaReqFailed: { $set: true },
                    isAdaDataLoading: { $set: false },
                    isAdaLoadingSuccess: { $set: false },
                    errorMsg: { $set: error },
                });
            },
            [adaVisualActionTypes.SET_STARTEND_FISCAL_YEARWEEK]: (state, { payload }) => {
                return immutable(state, {
                    startFiscalYearWeek: { $set: payload.start_date },
                    endFiscalYearWeek: { $set: payload.end_date },
                    startDateStamp: { $set: payload.start_stamp },
                    endDateStamp: { $set: payload.end_stamp },
                })
            },
            [adaVisualActionTypes.RESET_SELECTED_DATES]: (state) => {
                return immutable(state, {
                    startFiscalYearWeek: { $set: null },
                    endFiscalYearWeek: { $set: null },
                    startDateStamp: { $set: null },
                    endDateStamp: { $set: null },
                });
            },
            [adaVisualActionTypes.SET_CURR_FISCAL_YEARWEEK]: (state, { payload }) => {
                return immutable(state, {
                    currentFiscalYearWeek: { $set: payload },
                })
            },
            [adaVisualActionTypes.SET_ADA_DATA_TIME_CONSTRAINT]: (state, { payload }) => {
                return immutable(state, {
                    timeConstraint: { $set: payload },
                })
            },
            [adaVisualActionTypes.GET_ADA_DRIVERS_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isDriversDataLoading: { $set: true },
                    isDriversDataFailed: { $set: false },
                })
            },
            [adaVisualActionTypes.GET_ADA_DRIVERS_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isDriversDataLoading: { $set: false },
                    isDriversDataFailed: { $set: false },
                    adaDriversData: { $set: { ...response.data } },
                })
            },
            [adaVisualActionTypes.GET_ADA_DRIVERS_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isDriversDataLoading: { $set: false },
                    isDriversDataFailed: { $set: true },
                })
            },
            [adaVisualActionTypes.UPDATE_FORECAST_DATA]: (state, { payload }) => {
                const { date, updatedValue, fieldName } = payload;
                const updateAdaData = cloneDeep(state.adaVisualData);
                let updatedForecastItems = cloneDeep(state.updatedForecastData);
                updateAdaData.forEach(item => {
                    let newItem;
                    if (item.fy_fw === date) {
                        if (fieldName === 'adjusted_forecast') {
                            item['adjusted_forecast'] = updatedValue;
                            item['forecast_multiplier'] = updatedValue / item['ia_forecast'].toFixed(2);
                            newItem = cloneDeep(item);
                            newItem['forecast_multiplier'] = updatedValue / item['ia_forecast'].toFixed(5);
                        } else if (fieldName === 'forecast_multiplier') {
                            item['forecast_multiplier'] = updatedValue;
                            item['adjusted_forecast'] = Number((updatedValue * (item['ia_forecast'].toFixed(5))).toFixed(2));
                            newItem = cloneDeep(item);
                            newItem['adjusted_forecast'] = Number((updatedValue * (item['ia_forecast'].toFixed(5))).toFixed(5));
                        } else {
                            //No code here
                        }
                        // updatedForecastItems = { ...updatedForecastItems, [date]: item }
                        updatedForecastItems = { ...updatedForecastItems, [date]: newItem }
                    }
                });

                return immutable(state, {
                    adaVisualData: { $set: updateAdaData },
                    updatedForecastData: { $set: updatedForecastItems },
                    isForecastModified: { $set: true },
                    isHierarchyModified: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewSuccess: { $set: false },
                    isSaveSuccess: { $set: false },
                })
            },
            [adaVisualActionTypes.GET_ADA_FILTER_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isAdaFilterLoading: { $set: true },
                    isAdaFilterFailed: { $set: false },
                })
            },
            [adaVisualActionTypes.GET_ADA_FILTER_DATA_SUCCESS]: (state, { data }) => {
                return immutable(state, {
                    isAdaFilterLoading: { $set: false },
                    isAdaFilterFailed: { $set: false },
                    departmentOptions: { $set: data && data.departmentOptions ? data.departmentOptions : state.departmentOptions, },
                    genderOptions: { $set: data && data.genderOptions ? data.genderOptions : state.genderOptions },
                    subCatOptions: { $set: data && data.subCatOptions ? data.subCatOptions : state.subCatOptions, },
                    dcsOptions: { $set: data && data.dcsOptions ? data.dcsOptions : state.dcsOptions },
                    level5Options:{$set:data && data.level5Options ? data.level5Options :state.level5Options},
                    level6Options:{$set:data && data.level6Options ? data.level6Options :state.level6Options},
                    level7Options:{$set:data && data.level7Options ? data.level7Options :state.level7Options},
                    articleIdOptions: { $set: data && data.articleIdOptions ? data.articleIdOptions : state.articleIdOptions },
                    assortmentIndicatorOptions: { $set: data && data.assortmentIndicatorOptions ? data.assortmentIndicatorOptions : state.assortmentIndicatorOptions },
                    storeOptions: { $set: data && data.storeOptions ? data.storeOptions : state.storeOptions },
                    filterLabels: { $set: data && data.filterMapping ? data.filterMapping : state.filterMapping },
                    subClassOptions: { $set: data && data.subClassOptions ? data.subClassOptions : state.subClassOptions },
                })
            },
            [adaVisualActionTypes.GET_ADA_FILTER_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isAdaFilterLoading: { $set: false },
                    isAdaFilterFailed: { $set: true },
                })
            },
            [adaVisualActionTypes.SET_STANDALONE_REQBODY]: (state, { payload }) => {
                return immutable(state, {
                    adaStandAloneReqBody: { $set: payload },
                    isAdaAllocationModeSelected: { $set: false },
                });
            },
            [adaVisualActionTypes.RESET_ADA_VISUAL]: (state) => {
                return immutable(state, {
                    isAdaDataLoading: { $set: false },
                    isAdaReqFailed: { $set: false },
                    isAdaLoadingSuccess: { $set: false },
                    errorMsg: { $set: '' },
                    adaAllocationReqBody: { $set: {} },
                    isAdaAllocationModeSelected: { $set: false },
                    adaVisualData: { $set: [] },
                    adaVisualDataCopy: { $set: [] },
                    adaDriversData: { $set: {} },
                    isDriversDataLoading: { $set: false },
                    isDriversDataFailed: { $set: false },
                    timeConstraint: { $set: 'week' },
                    currentFiscalYearWeek: { $set: null },
                    startFiscalYearWeek: { $set: null },
                    endFiscalYearWeek: { $set: null },
                    startDateStamp: { $set: null },
                    endDateStamp: { $set: null },
                    adaGraphData: { $set: null },
                    isAdaFilterLoading: { $set: false },
                    isAdaFilterFailed: { $set: false },
                    filterLabels: { $set: {} },
                    departmentOptions: { $set: [] },
                    genderOptions: { $set: [] },
                    subCatOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    level5Options:{$set:[]},
                    level6Options:{$set:[]},
                    level7Options:{$set:[]},
                    articleIdOptions: { $set: [] },
                    adaStandAloneReqBody: { $set: {} },
                    isHierarchyLoading: { $set: false },
                    isHierarchyFailed: { $set: false },
                    hierarchyData: { $set: [] },
                    hierarchyDataCopy: { $set: [] },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isForecastModified: { $set: false },
                    isHierarchyModified: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    downloadCsvData: { $set: [] },
                    downloadExcelLowerData: { $set: [] },
                    updatedStoreData: { $set: {} },
                    updatedArticleWeek: { $set: {}},
                    updatedArticleData: { $set: {}},
                    updatedForecastData: { $set: {}},
                    excelData: { $set: [] },
                    nextIndexExcel: { $set: 0 },
                    totalCountExcel: { $set: 0 },
                    downloadLoading: { $set: false },
                    downloadNextIndex: { $set: 0 },
                    lowerHierarchyNextIndex: { $set:  0 },
                    totalRecords: { $set: 0 },
                });
            },
            [adaVisualActionTypes.RESET_ADA_FILTER_DATA]: (state) => {
                return immutable(state, {
                    isAdaFilterLoading: { $set: false },
                    isAdaFilterFailed: { $set: false },
                    filterLabels: { $set: {} },
                    departmentOptions: { $set: [] },
                    genderOptions: { $set: [] },
                    subCatOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    articleIdOptions: { $set: [] },
                    adaStandAloneReqBody: { $set: {} },
                    isAdaLoadingSuccess: { $set: false },
                    isAdaDataLoading: { $set: false },
                    isAdaReqFailed: { $set: false },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    downloadCsvData: { $set: [] },
                    downloadExcelLowerData: { $set: [] },
                    updatedStoreData: { $set: {} },
                    updatedArticleWeek: { $set: {}},
                    updatedArticleData: { $set: {}},
                    updatedForecastData: { $set: {}},
                    excelData: { $set: [] },
                    nextIndexExcel: { $set: 0 },
                    totalCountExcel: { $set: 0 },
                    downloadLoading: { $set: false },
                    downloadNextIndex: { $set: 0 },
                    lowerHierarchyNextIndex: { $set:  0 },
                    totalRecords: { $set: 0 },
                    hierarchyData: { $set: [] },
                    hierarchyDataCopy: { $set: [] },
                });
            },
            [adaVisualActionTypes.GET_ADA_HIERARCHY_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isHierarchyLoading: { $set: true },
                    isHierarchyFailed: { $set: false },
                });
            },
            [adaVisualActionTypes.GET_ADA_HIERARCHY_DATA_SUCCESS]: (state, { data, lowerHierarchyNextIndex, totalRecords }) => {
                const updatedHierarchyData = cloneDeep(state.hierarchyData);
                let uniqueArticles = new Set();
                
                updatedHierarchyData.push(...data);
                // This holds the list of articles fetched till now and visible on UI
                updatedHierarchyData.forEach(item => uniqueArticles.add(item.article));
                return immutable(state, {
                    isHierarchyLoading: { $set: false },
                    isHierarchyFailed: { $set: false },
                    hierarchyData: { $set: updatedHierarchyData },
                    hierarchyDataCopy: { $set: updatedHierarchyData },
                    /* 
                     Commented this as Search and pagination both calls this API so if user Reviews and then use
                     search or pagination user will not be able to save even with the changes. */
                    // isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    isForecastModified: { $set: false },
                    // Commented because now the API is paginated and we don't want to reset this flag on each data fetch. 
                    // isHierarchyModified: { $set: false }, 
                    lowerHierarchyNextIndex: { $set:  lowerHierarchyNextIndex || 0 },
                    totalRecords: { $set: totalRecords || 0 },
                    currentArticlesInView:  { $set: [...uniqueArticles] },
                });
            },
            [adaVisualActionTypes.GET_ADA_HIERARCHY_DATA_FAILED]: (state) => {
                return immutable(state, {
                    isHierarchyLoading: { $set: false },
                    isHierarchyFailed: { $set: true },
                });
            },
            [adaVisualActionTypes.UPDATE_FORECAST_MULTIPLIER]: (state, { payload }) => {
                const { strt_dt, end_dt, forecastValue,current_fw } = payload;
                const updateAdaData = cloneDeep(state.adaVisualData);                
                let updatedForecastItems = cloneDeep(state.updatedForecastData);

                const updatedGraphData = {
                    labels_data: [],
                    actual_data: [],
                    ia_data: [],
                    year:[],
                    adjusted_data: [],
                    ly_sales: [],

                };

                updateAdaData.forEach(item => {
                    if (item.fy_fw >= current_fw) {
                        item['forecast_multiplier'] = forecastValue;
                        item['adjusted_forecast'] = forecastValue * item['ia_forecast'];
                        
                        // Keep track of updated data for review and save. 
                        updatedForecastItems = { ...updatedForecastItems, [item.fy_fw]: item }
                    } else {
                        //No code here
                    }
                });

                updateAdaData.forEach((item) => {
                    updatedGraphData.labels_data.push(`W${item.week}(${moment(item.date).format('MM-DD')})`);
                    updatedGraphData.ia_data.push(item.ia_forecast);
                    updatedGraphData.ly_sales.push(item.ly_sales);
                    if (item.fy_fw < payload.current_fw) {
                        updatedGraphData.actual_data.push(item.actual_sales);
                        // item.fy_fw == payload.current_fw ?
                            updatedGraphData.adjusted_data.push(item.adjusted_forecast)
                            // : updatedGraphData.adjusted_data.push(undefined);
                    } else {
                        updatedGraphData.adjusted_data.push(item.adjusted_forecast);
                        updatedGraphData.year.push(item.year);
                    }
                });

                return immutable(state, {
                    adaVisualData: { $set: updateAdaData },
                    adaGraphData: { $set: updatedGraphData },
                    isForecastModified: { $set: true },
                    isHierarchyModified: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewSuccess: { $set: false },
                    isSaveSuccess: { $set: false },
                    updatedForecastData: { $set: updatedForecastItems },
                });
            },
            [adaVisualActionTypes.CHANGE_HIERARCHY]: (state, { payload }) => {
                const updateHierarchy = cloneDeep(state.hierarchyData);
                const updateStoreHierarchy = cloneDeep(state.storeHierarchyData);
                let updatedArticleWeek = cloneDeep(state.updatedArticleWeek); 
                let changedArticleData = cloneDeep(state.updatedArticleData);
                // If only article level update store it as an article week level update.
                if (payload.store == "") {
                    if (updatedArticleWeek[payload.art]) {
                        if (updatedArticleWeek[payload.art].indexOf(payload.date) == -1) {
                            updatedArticleWeek[payload.art].push(payload.date)
                        }
                    } else {
                        updatedArticleWeek[payload.art] = [payload.date];
                    }
                }
                let storeLevelSum = 0;
                let updatedData = cloneDeep(state.updatedStoreData);
                if (payload.store) {
                    // Update the Store level hierarchy.
                    updateStoreHierarchy[payload.art][payload.store].forEach( item => {
                        if (item['fy_fw'] === payload.date) {
                            item['adjusted_forecast'] = payload.input;
                            return;
                        }
                    })
                    // Calculate the some of store level for a particular week.
                    forEach(updateStoreHierarchy[payload.art], item => {
                        item.forEach( item => {
                            if (item['fy_fw'] === payload.date) {
                                storeLevelSum += item['adjusted_forecast'];
                                return;
                            }
                        })
                    })
                }
                
                updateHierarchy.forEach(item => {
                    if (item['article'] === payload.art && item['fy_fw'] === payload.date) {
                        if (payload.store) {
                            item['adjusted_forecast'] = storeLevelSum;
                            item['store'] = payload.store;
                            item['store_input'] = payload.input;
                            if (updatedData[payload.art]) {
                                // Check if the update for same week already existing ?
                                if (updatedData[payload.art][`${payload.date}`]) {
                                    // Check if the change is for the same store or different ?
                                    if (updatedData[payload.art][`${payload.date}`][`${payload.store}`]) {
                                        updatedData[payload.art][`${payload.date}`][`${payload.store}`] = item;
                                    } else {
                                        updatedData[payload.art][`${payload.date}`] = {...updatedData[payload.art][`${payload.date}`], [`${payload.store}`]: item}
                                    }
                                } else {
                                    updatedData[payload.art] = {...updatedData[payload.art], [`${payload.date}`]: { [`${payload.store}`] : item }}
                                }
                            } else {
                                updatedData[payload.art] = {[`${payload.date}`]: { [`${payload.store}`] : item }}
                            }

                            // Deleting the article updates for a week where there is store edit as well.
                            if (changedArticleData[payload.art]?.[payload.date]) {
                                delete changedArticleData[payload.art]?.[payload.date];
                            }
                        } else {
                            changedArticleData[payload.art] = { ...changedArticleData[payload.art], [payload.date]: item };
                            delete item['store']
                            delete item['store_input']
                            item['adjusted_forecast'] = payload.input;
                            if (updatedData[payload.art]?.[payload.date]) {
                                delete updatedData[payload.art][payload.date];
                            }
                        }
                        return
                    } else {
                        //No code here
                    }
                });

                return immutable(state, {
                    hierarchyData: { $set: updateHierarchy },
                    storeHierarchyData: { $set: updateStoreHierarchy },
                    updatedStoreData: { $set: updatedData},
                    updatedArticleWeek: { $set: updatedArticleWeek},
                    updatedArticleData: { $set: changedArticleData },
                    isReviewFailed: { $set: false },
                    isReviewSuccess: { $set: false },
                    isForecastModified: { $set: false },
                    isHierarchyModified: { $set: true },
                    isSaveSuccess: { $set: false },
                    isHierarchyTable: {$set: true}
                });
            },
            [adaVisualActionTypes.REVIEW_ADA_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: true },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                });
            },
            [adaVisualActionTypes.REVIEW_ADA_DATA_SUCCESS]: (state, { response }) => {
                let { changedArticles, isAdjustedForecastChanged } = response;
                let uniqueArticles = new Set();
                
                let fullHierarchyData = [...response.indHierarchyData.AdaData.lower_heirarchy_forecast_data];
                
                // This holds the list of all the unique articles visible on UI.
                fullHierarchyData.forEach(item => uniqueArticles.add(item.article));
                const updateStoreHierarchy = cloneDeep(state.storeHierarchyData);
                let updatedForecastItems = cloneDeep(state.updatedForecastData);
                let articlesInStoreData = Object.keys(updateStoreHierarchy);

                // Keep the store level updates for changed articles in the current review only, remove all old ones. 
                forEach(articlesInStoreData, article => {
                    if (changedArticles.indexOf(article) === -1) {
                        delete updateStoreHierarchy[article];
                    }
                })
                // If Adjusted Forecast not changed keep the last edits or discard.
                if (!isAdjustedForecastChanged) {
                    updatedForecastItems = {};
                }
                return immutable(state, {
                    hierarchyData: { $set: fullHierarchyData },
                    hierarchyDataCopy: { $set: fullHierarchyData },
                    adaVisualData: { $set: [...response.adaData] },
                    adaVisualDataCopy: { $set: [...response.adaData] },
                    adaGraphData: { $set: response.adaGraphData },
                    storeHierarchyData: { $set: updateStoreHierarchy },
                    storeHierarchyDataCopy: { $set: updateStoreHierarchy },
                    updatedForecastData: { $set: updatedForecastItems },
                    isReviewSuccess: { $set: true },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    currentArticlesInView:  { $set: [...uniqueArticles] },
                });
            },
            [adaVisualActionTypes.REVIEW_ADA_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: true },
                    isReviewReq: { $set: false },
                    errorMsg: { $set: error },
                });
            },
            [adaVisualActionTypes.SAVE_ADA_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isSaveReq: { $set: true },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    downloadCsvData: { $set: [] },
                    downloadExcelLowerData: { $set: [] }
                });
            },
            [adaVisualActionTypes.SAVE_ADA_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    hierarchyData: { $set: [...response.indHierarchyData.AdaData.lower_heirarchy_forecast_data] },
                    hierarchyDataCopy: { $set: [...response.indHierarchyData.AdaData.lower_heirarchy_forecast_data] },
                    adaVisualData: { $set: [...response.adaData] },
                    adaVisualDataCopy: { $set: [...response.adaData] },
                    adaGraphData: { $set: response.adaGraphData },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: true },
                    isSaveFailed: { $set: false },
                    isReviewSuccess: { $set: false },
                    isForecastModified: { $set: false },
                    isHierarchyModified: { $set: false },
                    // reset all the edited data logs
                    updatedStoreData: { $set: {} },
                    updatedArticleWeek: { $set: {}},
                    updatedArticleData: { $set: {}},
                    updatedForecastData: { $set: {}},
                    storeHierarchyData: { $set: {} }, 
                    storeHierarchyDataCopy: { $set: {} },
                });
            },
            [adaVisualActionTypes.SAVE_ADA_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: true },
                    errorMsg: { $set: error },
                });
            },
            [adaVisualActionTypes.RESTORE_ADA_DATA]: (state) => {
                const { adaVisualDataCopy } = state;
                return immutable(state, {
                    adaVisualData: { $set: adaVisualDataCopy },
                    isForecastModified: { $set: false },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                });
            },
            [adaVisualActionTypes.RESTORE_HIERARCHY_DATA]: (state) => {
                const { hierarchyDataCopy } = state;
                return immutable(state, {
                    hierarchyData: { $set: hierarchyDataCopy },
                    isHierarchyModified: { $set: false },
                    storeHierarchyData: { $set: {} },
                    storeHierarchyDataCopy: { $set: {} },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    // reset all the edited data logs
                    updatedStoreData: { $set: {} },
                    updatedArticleWeek: { $set: {}},
                    updatedArticleData: { $set: {}},
                });
            },
            [adaVisualActionTypes.CLEAR_HIERARCHY_DATA]: (state) => {
                const { hierarchyDataCopy } = state;
                return immutable(state, {
                    hierarchyData: { $set:[] },
                    hierarchyDataCopy: { $set: [] },
                    isHierarchyModified: { $set: false },
                    storeHierarchyData: { $set: {} },
                    storeHierarchyDataCopy: { $set: {} },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    // reset all the edited data logs
                    updatedStoreData: { $set: {} },
                    updatedArticleWeek: { $set: {}},
                    updatedArticleData: { $set: {}},
                    lowerHierarchyNextIndex: { $set:  0 },
                    totalRecords: { $set: 0 },
                });
            },
            [adaVisualActionTypes.DOWNLOAD_CSV]: (state) => {
                return immutable(state, {
                    downloadCsvLoading: { $set: true },
                    downloadCsvData: { $set: [] },
                })
            },
            [adaVisualActionTypes.DOWNLOAD_CSV_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    downloadCsvLoading: { $set: false },
                    downloadCsvError: { $set: null },
                    downloadCsvData: { $set: (payload && payload.excelData) || [] },

                })
            },
            [adaVisualActionTypes.DOWNLOAD_CSV_ERROR]: (state, payload) => {
                return immutable(state, {
                    downloadCsvLoading: { $set: false },
                    downloadCsvError: { $set: (payload && payload.error) || null },
                    downloadCsvData: { $set: [] }
                })
            },

            [adaVisualActionTypes.DOWNLOAD_EXCEL_LOWER]: (state) => {
                return immutable(state, {
                    downloadExcelLowerLoading: { $set: true },
                    downloadExcelLowerData: { $set: [] },
                })
            },
            [adaVisualActionTypes.DOWNLOAD_EXCEL_LOWER_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    downloadExcelLowerLoading: { $set: false },
                    downloadExcelLowerError: { $set: null },
                    downloadExcelLowerData: { $set: (payload && payload.excelData) || [] },

                })
            },
            [adaVisualActionTypes.DOWNLOAD_EXCEL_LOWER_ERROR]: (state, payload) => {
                return immutable(state, {
                    downloadExcelLowerLoading: { $set: false },
                    downloadExcelLowerError: { $set: (payload && payload.error) || null },
                    downloadExcelLowerData: { $set: [] }
                })
            },

            [adaVisualActionTypes.GET_ADA_STORE_HIERARCHY_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isStoreHierarchyLoading: { $set: true },
                    isStoreHierarchyFailed: { $set: false },
                });
            },
            [adaVisualActionTypes.GET_ADA_STORE_HIERARCHY_DATA_SUCCESS]: (state, { response }) => {
                const article = Object.keys(response.data.AdaData.lower_heirarchy_forecast_data)[0];
                const storeData = cloneDeep(state.storeHierarchyData)
                storeData[article] = response.data.AdaData.lower_heirarchy_forecast_data[article]
                return immutable(state, {
                    isStoreHierarchyLoading: { $set: false },
                    isStoreHierarchyFailed: { $set: false },
                    storeHierarchyData: { $set: storeData },
                    storeHierarchyDataCopy: { $set: storeData},
                });
            },
            [adaVisualActionTypes.GET_ADA_STORE_HIERARCHY_DATA_FAILED]: (state) => {
                return immutable(state, {
                    isStoreHierarchyLoading: { $set: false },
                    isStoreHierarchyFailed: { $set: true },
                });
            },
            [adaVisualActionTypes.GENERATE_EXCEL]: (state, { payload }) => {
                return immutable(state, {
                  excelSuccess: { $set: false },
                  excelError: { $set: false },
                  downloadLoading: { $set: true }
                });
              },
              [adaVisualActionTypes.GENERATE_EXCEL_ERROR]: (state, { payload }) => {
                return immutable(state, {
                  excelData: { $set: [] },
                  loading: { $set: false },
                  excelError: { $set: true },
                  excelSuccess: { $set: false },
                  totalCountExcel: { $set: 0 },
                  nextIndexExcel: { $set: 0 },
                });
              },
              [adaVisualActionTypes.GENERATE_EXCEL_SUCCESS]: (state, { payload }) => {
                return immutable(state, {
                  excelData: { $set: (payload && payload.data) || [] },
                  loading: { $set: false },
                  excelError: { $set: false },
                  excelSuccess: { $set: true },
                  totalCountExcel: { $set: (payload && payload.totalCountExcel) || 0 },
                  nextIndexExcel: { $set: (payload && payload.nextIndexExcel) || 0 },
                  downloadNextIndex: { $set: (payload && state.downloadLoading && payload.nextIndex) || 0 },
                });
              },
              [adaVisualActionTypes.RESET_DOWNLOAD_EXCEL]: (state, { payload }) => {
                return immutable(state, {
                  excelData: { $set: [] },
                  nextIndexExcel: { $set: 0 },
                  totalCountExcel: { $set: 0 },
                  downloadLoading: { $set: false },
                  downloadNextIndex: { $set: 0 }
                });
              },
              [adaVisualActionTypes.RESET_ADA_VISUAL_DATA]: (state) => {
                return immutable(state, {
                    isAdaDataLoading: { $set: false },
                    isAdaReqFailed: { $set: false },
                    isAdaLoadingSuccess: { $set: false },
                    errorMsg: { $set: '' },
                    adaAllocationReqBody: { $set: {} },
                    isAdaAllocationModeSelected: { $set: false },
                    adaVisualData: { $set: [] },
                    adaVisualDataCopy: { $set: [] },
                    adaDriversData: { $set: {} },
                    isDriversDataLoading: { $set: false },
                    isDriversDataFailed: { $set: false },
                    timeConstraint: { $set: 'week' },
                    currentFiscalYearWeek: { $set: null },
                    startFiscalYearWeek: { $set: null },
                    endFiscalYearWeek: { $set: null },
                    startDateStamp: { $set: null },
                    endDateStamp: { $set: null },
                    adaGraphData: { $set: null },
                    isAdaFilterLoading: { $set: false },
                    isAdaFilterFailed: { $set: false },
                    // filterLabels: { $set: {} },
                    // departmentOptions: { $set: [] },
                    // genderOptions: { $set: [] },
                    // subCatOptions: { $set: [] },
                    // dcsOptions: { $set: [] },
                    // level5Options:{$set:[]},
                    // level6Options:{$set:[]},
                    // level7Options:{$set:[]},
                    // articleIdOptions: { $set: [] },
                    adaStandAloneReqBody: { $set: {} },
                    isHierarchyLoading: { $set: false },
                    isHierarchyFailed: { $set: false },
                    hierarchyData: { $set: [] },
                    hierarchyDataCopy: { $set: [] },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isForecastModified: { $set: false },
                    isHierarchyModified: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    downloadCsvData: { $set: [] },
                    downloadExcelLowerData: { $set: [] },
                    updatedStoreData: { $set: {} },
                    updatedArticleWeek: { $set: {}},
                    updatedArticleData: { $set: {}},
                    updatedForecastData: { $set: {}},
                    excelData: { $set: [] },
                    nextIndexExcel: { $set: 0 },
                    totalCountExcel: { $set: 0 },
                    downloadLoading: { $set: false },
                    downloadNextIndex: { $set: 0 },
                    lowerHierarchyNextIndex: { $set:  0 },
                    totalRecords: { $set: 0 },
                });
            },
        },
        adaVisualInitialState
    ),
};

export default adaVisualReducer;