import { put, all, call, takeEvery, takeLatest } from "redux-saga/effects";
import * as AdaActionsTypes from "./actions/actionTypes";
import {
  getAdaMultiDeptFilter,
  getAdaMultiDeptData,
  updateADAMulti,
  getADAMultiGraphData,
} from "../../../routes/api";
import { ERROR_MSG } from "../../../constants/globalConstant";
import { isEmpty } from "lodash";
import { getWeeklyGraphData } from "../AdaUtilities";

const level_ln_map = {
  level1: "l1_name",
  level2: "l2_name",
  level3: "l3_name",
  subClass: "subclass",
  week: "week",
  store: "store",
};

function* fetchFilters(action) {
  try {
    const { payload, filterType } = action;
    let req = {};
    if (payload) {
      for (const key in payload) {
        if (!isEmpty(payload[key]))
          req[level_ln_map[key]] = payload[key].map((ele) => ele.value);
      }
    }
    if (filterType) {
      req["filter_type"] = filterType;
    }
    if (isEmpty(req)) {
      req = { week_flag: true };
    }
    const res = yield call(getAdaMultiDeptFilter, req);

    if (res.data.status) {
      const data = {};
      if (res.data?.weeks?.length) {
        data["weeks"] = res.data?.weeks.map((week) => ({
          value: week,
          label: week,
        }));
      }
      data["filterMapping"] = res.data?.filterMapping;
      for (const topObject in res.data.data[0]) {
        data[topObject] = res.data.data[0][topObject]
          ?.filter((val) => val)
          .map((element) => ({
            value: element,
            label: element,
          }));
      }

      yield put({
        type: AdaActionsTypes.GET_FILTERS_ACTION_SUCCESS,
        data: data,
      });
    }
  } catch (error) {
    yield put({
      type: AdaActionsTypes.GET_FILTERS_ACTION_ERROR,
      error: ERROR_MSG,
    });
  }
}

function* getData(action) {
  const { payload } = action;

  try {
    const req = {
      row_count: 1000,
      row_index: payload.row_index,
    };
    if (payload) {
      for (const key in payload) {
        if (!isEmpty(payload[key]) && level_ln_map[key]) {
          req[level_ln_map[key]] = payload[key]
            .map((ele) => ele.value)
            .filter((item) => item !== "*");
        } else if (!isEmpty(payload[key])) {
          req[key] = payload[key];
        }
      }
    }
    if (payload?.past_flag) {
      req["past_flag"] = true;
      delete req.week;
    }
    const res = yield call(getAdaMultiDeptData, req);
    let response = {
      data: res.data.data,
      totalCount: res.data.totalCount,
      nextIndex: res.data.row_index,
    };
    response.data.forEach((item) => {
      item.unique = item.subclass + item.store + item.fy_fw;
      item.week = item.fy_fw;
      item.ia_forecast = item.ia_forecast % 1 ? item.ia_forecast.toFixed(2): item.ia_forecast;
      item.adjusted_forecast = item.adjusted_forecast % 1 ? item.adjusted_forecast.toFixed(2): item.adjusted_forecast;
    });
    yield put({
      type: payload?.past_flag
        ? AdaActionsTypes.GET_ADA_MULTI_PAST_DATA_SUCCESS
        : AdaActionsTypes.GET_ADA_MULTI_DATA_SUCCESS,
      ...response,
    });
  } catch (error) {
    yield put({
      type: payload?.past_flag
        ? AdaActionsTypes.GET_ADA_MULTI_DATA_ERROR
        : AdaActionsTypes.GET_ADA_MULTI_PAST_DATA_ERROR,
      error: ERROR_MSG,
    });
  }
}

function* updateData(action) {
  const { payload } = action;

  try {
    const req = {
      multiplier: payload.multiplier,
    };
    if (payload) {
      for (const key in payload) {
        if (!isEmpty(payload[key]) && level_ln_map[key]) {
          req[level_ln_map[key]] = payload[key]
            .map((ele) => ele.value)
            .filter((item) => item !== "*");
        } else if (!isEmpty(payload[key])) {
          req[key] = payload[key];
        }
      }
    }
    if (payload?.set_all) {
      req["set_all"] = true;
    }
    const res = yield call(updateADAMulti, req);

    if (res?.data.status) {
      let data = res.data;
      yield put({
        type: AdaActionsTypes.UPDATE_ADA_MULTI_DATA_SUCCESS,
        data: data,
      });
    } else {
      yield put({
        type: AdaActionsTypes.UPDATE_ADA_MULTI_DATA_ERROR,
        error: ERROR_MSG,
      });
    }
  } catch (error) {
    yield put({
      type: AdaActionsTypes.UPDATE_ADA_MULTI_DATA_ERROR,
      error: ERROR_MSG,
    });
  }
}

function* getGraphData(action) {
  const { payload } = action;

  try {
    const req = {};
    if (payload) {
      for (const key in payload) {
        if (!isEmpty(payload[key]) && level_ln_map[key]) {
          req[level_ln_map[key]] = payload[key]
            .map((ele) => ele.value)
            .filter((item) => item !== "*");
        } else if (!isEmpty(payload[key])) {
          req[key] = payload[key];
        }
      }
    }

    delete req.week

    const res = yield call(getADAMultiGraphData, req);
    let response = {
      adaGraphData: getWeeklyGraphData(res.data.data, payload.current_fw),
      accuracy: res.data.accuracy,
      weeksData: res.data.data
    };

    yield put({
      type: AdaActionsTypes.GET_ADA_MULTI_GRAPH_DATA_SUCCESS,
      ...response,
    });
  } catch (error) {
    yield put({
      type: AdaActionsTypes.GET_ADA_MULTI_GRAPH_DATA_ERROR,
      error: ERROR_MSG,
    });
  }
}

function* getFiltersData() {
  yield takeEvery(AdaActionsTypes.GET_FILTERS_ACTION, fetchFilters);
}

function* getADAMultiDeptData() {
  yield takeEvery(AdaActionsTypes.GET_ADA_MULTI_DATA, getData);
}

function* getADAMultiDeptPastData() {
  yield takeEvery(AdaActionsTypes.GET_ADA_MULTI_PAST_DATA, getData);
}

function* updateADAMultiData() {
  yield takeEvery(AdaActionsTypes.UPDATE_ADA_MULTI_DATA, updateData);
}

function* getMultiGraphData() {
  yield takeEvery(AdaActionsTypes.GET_ADA_MULTI_GRAPH_DATA, getGraphData);
}

export function* adaVisualMultiSagas() {
  yield all([
    getFiltersData(),
    getADAMultiDeptData(),
    getADAMultiDeptPastData(),
    updateADAMultiData(),
    getMultiGraphData(),
  ]);
}
