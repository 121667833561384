export const initialState = {
    filtersData: {},
    data: [],
    dataSuccess: false,
    dataLoading: false,
    dataError: null,
    totalCount: 0,
    nextIndex: 0,
    pastData: [],
    pastDataLoading: false,
    pastDataError: null,
    pastDataCount: 0,
    pastDataNextIndex: 0,
    updateLoading: false,
    updateError: null,
    updateSuccess: false,
    graphData: [],
    graphLoading: false,
    graphError: null,
    graphSuccess: false,
    accuracy: {},
    weeksData: []
}